export const enum StoreId {
  AUTH = 'auth',
  BANNERS = 'banners',
  CONFIG = 'config',
  CONTRACTS_AND_PAYMENTS = 'contractsAndPayments',
  EVENTS = 'events',
  FOOTER = 'footer',
  GRADE_BOOK = 'gradeBook',
  HEADER = 'header',
  PROGRESS = 'progress',
  SCHEDULE = 'schedule',
  SCHEDULE_2035 = 'schedule2035',
  SCHEDULE_CHANGE = 'scheduleChange',
  USER = 'user',
}
