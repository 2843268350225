export enum ContactType {
  SCHOOL_CHILD = 'SchoolChild',
  PARENT = 'Parent',
  EMPLOYEE = 'Employee',
}

export enum LessonAndExamSubjectsType {
  WEBINAR = 'webinar',
  OFFLINE = 'offline',
  VIDEO = 'video',
  STREAM = 'stream',
}

export enum ConversationsType {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum VacationsType {
  SELF_STUDY = 'self_study',
  VACATION = 'vacation',
}

export enum EventType {
  LESSONS = 'lessons',
  EXAM_SUBJECTS = 'examSubjects',
  CONVERSATIONS = 'conversations',
  VACATIONS = 'vacations',
  SELF_STUDY = 'selfStudy',
}

export enum EventIcon {
  CONVERSATIONS = 'conversations',
  EXAM_SUBJECTS = 'exam-subjects',
  OFFLINE = 'offline',
  ONLINE = 'online',
  VACATIONS = 'vacations',
  SELF_STUDY = 'self-study',
}

export enum CalendarType {
  MONTH = 'month',
  WEEK = 'week',
}
